import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';

import { LoginFormComponent } from '@app/auth/components/login-form/login-form.component';
import { RegisterFormComponent } from '@app/auth/components/register-form/register-form.component';
import { ForgotFormComponent } from '@app/auth/components/forgot-form/forgot-form.component';
import { ResetFormComponent } from '@app/auth/components/reset-form/reset-form.component';

export const COMPONENTS = [
  LoginFormComponent,
  RegisterFormComponent,
  ForgotFormComponent,
  ResetFormComponent
];

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormsModule, RouterModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class ComponentsModule {}
